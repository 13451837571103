import React from 'react'
import { Grid, IconButton, CircularProgress } from '@mui/material'
import {
    Session,
    useCancelSessionMutation,
    useDeleteSessionMutation,
    useEnableSessionMutation,
    useGetOrderBySessionIdLazyQuery,
} from '../../../generated/graphql'
import PaymentIcon from '@mui/icons-material/Payment'
import CreditScoreIcon from '@mui/icons-material/CreditScore'

import { useNavigate } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete'
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'

export type ComponentProps = {
    session: Session
    refetch: () => void
}

const SessionActionButtons = ({ session, refetch }: ComponentProps) => {
    const navigate = useNavigate()

    const [sessionLoading, setSessionLoading] = React.useState<boolean>(false)

    const [getOrderBySessionId] = useGetOrderBySessionIdLazyQuery()

    const [deleteSession] = useDeleteSessionMutation()

    const [cancelSession] = useCancelSessionMutation()

    const [enableSession] = useEnableSessionMutation()

    const paySession = React.useCallback(
        async (session: Session) => {
            setSessionLoading(true)
            const orderStillCreated = await getOrderBySessionId({
                variables: {
                    data: {
                        sessionId: session.id,
                    },
                },
            })
            if (orderStillCreated?.data?.orderBySessionId) {
                navigate(
                    `/orders/update/${orderStillCreated?.data?.orderBySessionId?.id}`
                )
            } else {
                navigate(`/orders/new/session/${session.id}`)
            }
            setSessionLoading(false)
        },
        [navigate, getOrderBySessionId]
    )

    const deleteSessionHandler = React.useCallback(
        async (sessionId: string) => {
            setSessionLoading(true)
            await deleteSession({
                variables: {
                    data: {
                        id: sessionId,
                    },
                },
            })
            refetch()
            setSessionLoading(false)
        },
        [deleteSession, refetch]
    )

    const cancelSessionHandler = React.useCallback(
        async (session: Session) => {
            if (!session?.id) return
            setSessionLoading(true)
            if (session.cancelledAt) {
                await enableSession({
                    variables: {
                        data: {
                            id: session.id,
                        },
                    },
                })
            } else {
                await cancelSession({
                    variables: {
                        data: {
                            id: session.id,
                        },
                    },
                })
            }
            refetch()
            setSessionLoading(false)
        },
        [enableSession, cancelSession, refetch]
    )

    return (
        <Grid display={'flex'} alignItems={'center'} justifyContent={'center'}>
            {sessionLoading ? (
                <CircularProgress
                    size={15}
                    sx={{
                        marginLeft: 1.25,
                        marginRight: 1.25,
                    }}
                />
            ) : (
                <>
                    {session.transaction ? (
                        <>
                            <IconButton disabled={true}>
                                <ThumbUpOffAltIcon
                                    sx={{ color: 'primary.light' }}
                                />
                            </IconButton>
                            <IconButton
                                onClick={() =>
                                    navigate(
                                        `/transactions/invoice/${session?.transaction?.id}`
                                    )
                                }
                            >
                                <CreditScoreIcon sx={{ color: 'green' }} />
                            </IconButton>
                        </>
                    ) : (
                        <>
                            <IconButton
                                onClick={() => cancelSessionHandler(session)}
                                sx={{
                                    '&:hover': {
                                        bgcolor: session.cancelledAt
                                            ? 'primary.main'
                                            : 'secondary.main',
                                    },
                                }}
                            >
                                <ThumbDownOffAltIcon
                                    sx={{
                                        color: session.cancelledAt
                                            ? 'secondary.main'
                                            : 'primary.main',
                                    }}
                                />
                            </IconButton>
                            <IconButton
                                onClick={() => paySession(session)}
                                disabled={session.cancelledAt}
                            >
                                <PaymentIcon />
                            </IconButton>
                        </>
                    )}

                    <IconButton
                        disabled={!!session.transaction}
                        onClick={() => deleteSessionHandler(session?.id || '')}
                    >
                        <DeleteIcon />
                    </IconButton>
                </>
            )}
        </Grid>
    )
}

export default React.memo(SessionActionButtons)
