import { Box, Button, Grid, IconButton, TextField } from '@mui/material'
import React from 'react'
import {
    useDeleteCustomerMutation,
    useGetCustomersQuery,
} from '../../../../generated/graphql'
import Loading from '../../../loading'
import EditIcon from '@mui/icons-material/Edit'
import { useNavigate } from 'react-router-dom'
import VisibilityIcon from '@mui/icons-material/Visibility'

import SpaIcon from '@mui/icons-material/Spa'
import DeleteButton from '../../../deleteButton'
import CustomDataGrid from '../../../customDataGrid'

const CustomersTable = () => {
    const navigate = useNavigate()
    const [deleting, setDeleteting] = React.useState<string[]>([])

    const [search, setSearch] = React.useState('')

    const [deleteCustomer, { loading: loadingDelete }] =
        useDeleteCustomerMutation()

    const { data, loading } = useGetCustomersQuery()

    const handleDelete = React.useCallback(
        (id: string | undefined) => {
            if (!id) return
            setDeleteting([...deleting, id])
            deleteCustomer({
                variables: {
                    data: {
                        id,
                    },
                },
                onCompleted: () => {
                    setDeleteting(deleting.filter((d) => d !== id))
                },
                refetchQueries: ['getCustomers', 'getPeople'],
            })
        },
        [deleteCustomer, deleting, setDeleteting]
    )

    const customers = React.useMemo(() => {
        return data?.customers || []
    }, [data])
    const columns = React.useMemo(
        () => [
            { field: 'id', headerName: 'ID', flex: 1 },
            { field: 'name', headerName: 'Name', flex: 1 },
            { field: 'email', headerName: 'Email', flex: 1 },
            { field: 'phone', headerName: 'Phone', flex: 1 },
            { field: 'dni', headerName: 'DNI', flex: 1 },
            {
                field: 'actions',
                headerName: 'Actions',
                width: 150,
                renderCell: ({ row }: { row: any }) => (
                    <Grid
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        sx={{ width: '100%', height: '100%' }}
                    >
                        <IconButton onClick={() => navigate(`${row.id}`)}>
                            <VisibilityIcon />
                        </IconButton>
                        <IconButton
                            onClick={() =>
                                navigate('/sessions/new', {
                                    state: row,
                                })
                            }
                        >
                            <SpaIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => navigate(`update/${row.id}`)}
                        >
                            <EditIcon />
                        </IconButton>
                        {loadingDelete && deleting.includes(row.id || '') ? (
                            <Box
                                sx={{ marginLeft: '8px', marginRight: '8px' }}
                                display={'flex'}
                                alignItems={'center'}
                            >
                                <Loading size={'1.6em'} />
                            </Box>
                        ) : (
                            <DeleteButton onDelete={handleDelete} item={row} />
                        )}
                    </Grid>
                ),
            },
        ],
        [handleDelete, navigate, deleting, loadingDelete]
    )

    const rows = React.useMemo(() => {
        const searched = search
            ? customers.filter(
                  (c) =>
                      (c.fullName?.toLocaleLowerCase() || '').includes(
                          search
                      ) ||
                      (c.email?.toLocaleLowerCase() || '').includes(search) ||
                      (c.phone || '').includes(search) ||
                      (c.surname?.toLocaleLowerCase() || '').includes(search) ||
                      (c.dni || '').includes(search)
              )
            : customers
        return searched.map((customer) => {
            return {
                id: customer.id,
                name: customer.fullName,
                email: customer.email,
                phone: customer.phone,
                dni: customer.dni,
            }
        })
    }, [customers, search])

    return loading ? (
        <Loading />
    ) : (
        <>
            <Grid
                display={'flex'}
                alignItems={'center'}
                sx={{ marginBottom: 2 }}
            >
                <TextField
                    label="Search"
                    variant="outlined"
                    size={'small'}
                    fullWidth
                    onChange={(e) =>
                        setSearch((e.target.value || '').toLowerCase())
                    }
                />
                <Button variant="contained" sx={{ marginLeft: 2 }}>
                    seach
                </Button>
            </Grid>
            <CustomDataGrid
                rows={rows}
                columns={columns}
                loading={loading}
                density="compact"
            />
        </>
    )
}

export default React.memo(CustomersTable)
