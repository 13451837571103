import { Order, OrderRow } from '../generated/graphql'

export const rowPrice = (row: OrderRow) => {
    const price =
        row.article?.price ||
        row.session?.therapist?.therapist?.defaultPrice ||
        0

    const amount = row.amount || row.session?.duration || 0
    const totalWithoutDiscount = amount * price
    const discount = (totalWithoutDiscount * (row.discount || 0)) / 100

    return {
        price,
        amount: amount,
        discount: discount,
        total: totalWithoutDiscount - discount,
    }
}

export const orderPrice = (order: Order) => {
    return order.rows?.reduce((acc, row) => {
        return acc + rowPrice(row).total
    }, 0)
}

export const orderCategories = (order: Order | undefined) => {
    return (order?.rows || [])
        .map(
            (o) =>
                o.article?.defaultCategory?.name ||
                o.session?.therapist?.therapist?.defaultCategory?.name ||
                ''
        )
        .join(', ')
}
