import React from 'react'
import CustomerForm from '../customerForm'
import {
    CreateCustomerMutation,
    Person,
    useCreateCustomerMutation,
} from '../../../../generated/graphql'
import Loading from '../../../loading'
import { useNavigate } from 'react-router-dom'
import { addToQuery } from '../../../../apollo/resolvers'

const NewCustomer = () => {
    const navigate = useNavigate()
    const [create, { loading }] = useCreateCustomerMutation()

    const submit = React.useCallback(
        (person: Person) => {
            create({
                variables: {
                    data: {
                        name: person.name,
                        surname: person.surname,
                        email: person.email,
                        phone: person.phone,
                        dni: person.dni,
                    },
                },
                onCompleted: (data: CreateCustomerMutation) => {
                    addToQuery(data.createCustomer, 'people')
                    addToQuery(data.createCustomer, 'customers')
                    navigate('/people/customers')
                },
            })
        },
        [create, navigate]
    )

    return loading ? (
        <Loading />
    ) : (
        <CustomerForm
            customer={{
                name: '',
                email: '',
                phone: '',
                dni: '',
            }}
            submit={submit}
        />
    )
}

export default React.memo(NewCustomer)
