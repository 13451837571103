import { Button, IconButton, TextField } from '@mui/material'
import Grid from '@mui/material/Grid'
import React from 'react'
import {
    Order,
    Article,
    useGetArticlesQuery,
    useGetCustomersQuery,
    Person,
    OrderRow,
} from '../../../generated/graphql'
import DeleteIcon from '@mui/icons-material/Delete'
import { isEmpty } from 'lodash'
import AutocompleteSelector from '../../selectors/AutocompleteSelector'
import DateField from '../../pickers/dateField'
import { rowPrice } from '../../../lib/Order'
import CustomDataGrid from '../../customDataGrid'

export type ComponentProps = {
    order: Order

    submit: (order: Order, pay: boolean) => void
}

const OrderForm = ({ order, submit }: ComponentProps) => {
    const [updatedOrder, setUpdatedOrder] = React.useState<Order>(order)
    const [selectedArticle, setSelectedArticle] = React.useState<
        Article | undefined
    >()

    const [selectedAmount, setSelectedAmount] = React.useState<number>(1)
    const [selectedDiscount, setSelectedDiscount] = React.useState<number>(0)

    const { data: articlesData, loading: loadingArticles } =
        useGetArticlesQuery()

    const articles = React.useMemo(() => {
        return articlesData?.articles || []
    }, [articlesData?.articles])

    const { data: customersData, loading: loadingCustomers } =
        useGetCustomersQuery()

    const customers = React.useMemo(() => {
        return customersData?.customers || []
    }, [customersData?.customers])

    const handleDeleteRow = React.useCallback((row: OrderRow) => {
        setUpdatedOrder((prev) => ({
            ...prev,
            rows: prev?.rows?.filter((r) => r.article?.id !== row.id),
        }))
    }, [])

    const addArticle = React.useCallback(() => {
        setUpdatedOrder((prev) => {
            const existingArticle = prev?.rows?.find(
                (row) => row.article?.id === selectedArticle?.id
            )
            if (existingArticle) {
                return {
                    ...prev,
                    rows: prev?.rows?.map((row) =>
                        row.article?.id === selectedArticle?.id
                            ? {
                                  ...row,
                                  amount: (row.amount || 0) + selectedAmount,
                                  discount: selectedDiscount,
                              }
                            : row
                    ),
                }
            }

            return {
                ...prev,
                rows: [
                    ...(prev?.rows || []),
                    {
                        article: selectedArticle,
                        amount: selectedAmount,
                        discount: selectedDiscount,
                    },
                ],
            }
        })
    }, [selectedAmount, selectedArticle, selectedDiscount])

    const orderRowColumns = React.useMemo(() => {
        return [
            { headerName: 'Row', field: 'id', flex: 1 },
            { headerName: 'Item name', field: 'item', flex: 1 },
            { headerName: 'Item price', field: 'price', flex: 1 },
            {
                headerName: 'Item amount',
                field: 'amount',
                flex: 1,
                editable: true,
            },
            {
                headerName: 'Item discount',
                field: 'discount',
                flex: 1,
                editable: true,
            },
            { headerName: 'Total', field: 'total', flex: 1, editable: true },
            {
                field: 'actions',
                headerName: 'Actions',
                width: 120,
                renderCell: ({ row }: { row: any }) => (
                    <Grid
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        sx={{ width: '100%', height: '100%' }}
                    >
                        <IconButton onClick={() => handleDeleteRow(row)}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                ),
            },
        ]
    }, [handleDeleteRow])

    const orderRowRows = React.useMemo(() => {
        return (updatedOrder?.rows || []).map((row) => {
            const { price, amount, discount, total } = rowPrice(row)
            return {
                id: (row.article || row.session)?.id,
                item: row.article
                    ? row?.article?.name
                    : `${row?.session?.therapist?.name} ${row.session?.therapist?.surname}`,
                price,
                amount,
                total,
                discount,
            }
        })
    }, [updatedOrder])

    const onCellEditStop = React.useCallback(
        (parameters: any, newValue: any) => {
            if (!newValue?.target.value) return
            const column = parameters.field
            const rowId = parameters.row.id

            setUpdatedOrder((prev) => {
                return {
                    ...prev,
                    rows: prev?.rows?.map((r) => {
                        if (
                            r.article?.id === rowId ||
                            r.session?.id === rowId
                        ) {
                            if (column === 'amount') {
                                return {
                                    ...r,
                                    amount: parseInt(newValue.target.value),
                                }
                            }
                            if (column === 'discount') {
                                return {
                                    ...r,
                                    discount: parseInt(newValue.target.value),
                                }
                            }
                            if (column === 'total') {
                                const s =
                                    (r.article?.price ||
                                        r.session?.therapist?.therapist
                                            ?.defaultPrice ||
                                        0) * (r.amount || 0)

                                const desiredTotal = parseFloat(
                                    newValue.target.value
                                )

                                const discount = (-(desiredTotal - s) / s) * 100

                                return {
                                    ...r,
                                    discount,
                                }
                            }
                        }
                        return r
                    }),
                }
            })
        },
        [setUpdatedOrder]
    )

    return (
        <Grid container gap={3} flexDirection={'column'} component="form">
            <Grid sx={{ display: 'flex', gap: 2 }}>
                <AutocompleteSelector<Person>
                    data={
                        customers.map((c) => ({
                            label: `${c.name} ${c.surname}`,
                            id: c.id,
                        })) || []
                    }
                    fullWidth
                    selectedItemId={updatedOrder?.customer?.id || undefined}
                    selectorKey="customer"
                    setSelectedItem={(item) => {
                        const customer = customers.find(
                            (c) => c.id === item?.id
                        )
                        setUpdatedOrder((prev) => ({
                            ...prev,
                            customer: customer,
                        }))
                    }}
                    loading={loadingCustomers}
                />
                <DateField
                    value={updatedOrder.date}
                    onChange={(value) => {
                        setUpdatedOrder((prev) => ({ ...prev, date: value }))
                    }}
                />
            </Grid>
            <Grid sx={{ display: 'flex', gap: 2 }}>
                <AutocompleteSelector<Article>
                    data={
                        articles.map((a) => ({
                            label: `${a.name}`,
                            id: a.id,
                        })) || []
                    }
                    fullWidth
                    selectorKey="article"
                    setSelectedItem={(item) => {
                        const article = articles.find((a) => a.id === item?.id)
                        setSelectedArticle(article)
                    }}
                    loading={loadingArticles}
                />

                <TextField
                    fullWidth
                    size={'small'}
                    value={selectedAmount}
                    label="Amount (units / hours)"
                    type="text"
                    name="number"
                    onChange={(e) =>
                        !e.target.value
                            ? setSelectedAmount(0)
                            : setSelectedAmount(parseInt(e.target.value))
                    }
                />
                <TextField
                    fullWidth
                    size={'small'}
                    value={selectedDiscount}
                    label="Discount (%)"
                    type="text"
                    name="number"
                    onChange={(e) =>
                        e.target.value
                            ? setSelectedDiscount(parseInt(e.target.value))
                            : setSelectedDiscount(0)
                    }
                />
                <Button
                    variant="contained"
                    disabled={!selectedArticle}
                    onClick={addArticle}
                >
                    Add
                </Button>
            </Grid>
            <CustomDataGrid
                rows={orderRowRows}
                columns={orderRowColumns}
                density="compact"
                onCellEditStop={onCellEditStop}
            />
            <Grid display={'flex'} gap={3}>
                <Button
                    sx={{ flexGrow: 1 }}
                    variant="contained"
                    color="primary"
                    disabled={
                        !updatedOrder.customer || isEmpty(updatedOrder?.rows)
                    }
                    onClick={() => submit(updatedOrder, true)}
                >
                    Pay Order
                </Button>
            </Grid>
        </Grid>
    )
}

export default React.memo(OrderForm)
