import React from 'react'
import { useParams } from 'react-router-dom'
import {
    OrderRow,
    useGetCustomerResumeQuery,
    useGetPersonQuery,
} from '../../../../generated/graphql'
import Loading from '../../../loading'
import { Grid, Paper, Typography } from '@mui/material'
import PersonHeader from '../../personHeader'
import HeaderBigNumber from '../../../headerBigNumber'
import { groupBy, isEmpty, orderBy, sortBy } from 'lodash'
import { PieChart } from '@mui/x-charts'
import dayjs from '../../../../lib/dayjs'
import CustomDataGrid from '../../../customDataGrid'

const ViewCustomer = () => {
    const params = useParams()

    const { data: personData, loading } = useGetPersonQuery({
        variables: {
            data: {
                id: params.id,
            },
        },
        skip: !params.id,
    })
    const { data: customerResumeData, loading: resumeLoading } =
        useGetCustomerResumeQuery({
            variables: {
                data: {
                    id: params.id,
                },
            },
            skip: !params.id,
        })

    const globalInvoiced = React.useMemo((): number => {
        return (
            customerResumeData?.customerResume?.transactions?.reduce(
                (acc, curr) => {
                    return acc + (curr?.amount as number) || 0
                },
                0
            ) || 0
        )
    }, [customerResumeData?.customerResume?.transactions])

    const sessions = React.useMemo(
        () =>
            sortBy(customerResumeData?.customerResume?.sessions || [], 'date'),
        [customerResumeData?.customerResume?.sessions]
    )

    const transactions = React.useMemo(
        () => customerResumeData?.customerResume?.transactions || [],
        [customerResumeData?.customerResume?.transactions]
    )

    const services = React.useMemo(() => {
        const orderRows = transactions.reduce<OrderRow[]>(
            (acc, transaction) => {
                if (!isEmpty(transaction.order?.rows)) {
                    transaction?.order?.rows?.forEach((row) => {
                        acc.push(row)
                    })
                }
                return acc
            },
            []
        )
        const agg = groupBy(orderRows, (row) => row.category?.name)
        return Object.keys(agg).map((key) => {
            return {
                id: key,
                label: `${key} (€)`,
                value: agg[key].reduce((acc, curr) => {
                    return (
                        acc +
                        (curr?.article?.price ||
                            curr.session?.therapist?.therapist?.defaultPrice ||
                            0) *
                            (curr?.amount || 0)
                    )
                }, 0),
            }
        })
    }, [transactions])

    const therapists = React.useMemo(() => {
        const agg = groupBy(
            sessions,
            (session) =>
                `${session.therapist?.name} ${session.therapist?.surname}`
        )
        return Object.keys(agg).map((key) => {
            return {
                id: key,
                label: `${key} (u)`,
                value: agg[key].length,
            }
        })
    }, [sessions])

    const articleRows = React.useMemo(() => {
        const articles: {
            id: string
            name?: string
            date?: string
            price?: string
            transaction?: string
        }[] = []
        transactions.forEach((transaction) => {
            ;(transaction?.order?.rows || []).forEach((row) => {
                if (row.article)
                    articles.push({
                        id: `${row.article.id}-${transaction.id}`,
                        name: row.article.name,
                        date: dayjs(transaction.date).format('DD/MM/YYYY'),
                        price: `${row.article.price}€`,
                        transaction: transaction.id,
                    })
            })
        })
        return articles
    }, [transactions])

    const articleColumns = React.useMemo(
        () => [
            { field: 'id', headerName: 'ID', width: 0 },
            { field: 'name', headerName: 'Name', flex: 1 },
            { field: 'date', headerName: 'Date', flex: 1 },
            { field: 'price', headerName: 'Price', flex: 1 },
            { field: 'transaction', headerName: 'Transaction', flex: 1 },
        ],
        []
    )

    const transactionRows = React.useMemo(() => {
        return orderBy(transactions, 'date', 'desc').map((transaction) => {
            return {
                id: transaction.id,
                sessions: transaction.order?.rows?.length,
                date: dayjs(transaction.date).format('DD/MM/YYYY'),
                amount: `${transaction.amount}€`,
            }
        })
    }, [transactions])

    const transactionColumns = React.useMemo(
        () => [
            { field: 'id', headerName: 'ID', width: 0 },
            { field: 'date', headerName: 'Date', flex: 1 },
            { field: 'amount', headerName: 'Payed', flex: 1 },
            { field: 'sessions', headerName: 'Transaction', flex: 1 },
        ],
        []
    )

    const sessionRows = React.useMemo(() => {
        return orderBy(sessions, 'date', 'desc').map((session) => {
            return {
                id: session.id,
                date: dayjs(session.date).format('DD/MM/YYYY'),
                therapist: session.therapist?.fullName,
            }
        })
    }, [sessions])

    const sessionColumns = React.useMemo(
        () => [
            { field: 'id', headerName: 'ID', width: 0 },
            { field: 'date', headerName: 'Date', flex: 1 },
            { field: 'therapist', headerName: 'Therapist', flex: 1 },
        ],
        []
    )

    console.log('personData', customerResumeData?.customerResume)

    return loading || resumeLoading ? (
        <Loading />
    ) : (
        <Grid>
            {personData?.person && (
                <Grid display="flex" flexDirection={'column'} gap={3}>
                    <Grid display="flex" flexDirection={'row'} gap={3}>
                        <PersonHeader person={personData?.person} />
                        <HeaderBigNumber
                            label={'Global Invoiced'}
                            value={globalInvoiced}
                            symbol="€"
                            isDecimal={true}
                        />
                        <HeaderBigNumber
                            label={'Sessions scheduled'}
                            value={sessions.length}
                            symbol="none"
                            isDecimal={false}
                        />
                        <HeaderBigNumber
                            label={'Transactions'}
                            value={transactions.length}
                            symbol="none"
                            isDecimal={false}
                        />
                    </Grid>
                    <Grid
                        display="flex"
                        flexDirection={'row'}
                        gap={3}
                        sx={{ height: 320 }}
                    >
                        <Paper sx={{ padding: 3, height: 300, width: '50%' }}>
                            <PieChart
                                series={[
                                    {
                                        data: services,
                                    },
                                ]}
                            />
                        </Paper>
                        <Paper sx={{ padding: 3, height: 300, width: '50%' }}>
                            <PieChart
                                series={[
                                    {
                                        data: therapists,
                                    },
                                ]}
                            />
                        </Paper>
                    </Grid>
                    <Typography variant="h3">Purchassed articles</Typography>
                    <CustomDataGrid
                        rows={articleRows}
                        columns={articleColumns}
                        density="compact"
                        hideFooter
                    />
                    <Grid
                        display="flex"
                        flexDirection={'row'}
                        gap={3}
                        justifyContent={'space-between'}
                    >
                        <Typography variant="h3">Sessions</Typography>
                        <Typography variant="h3">Transactions</Typography>
                    </Grid>
                    <Grid display="flex" flexDirection={'row'} gap={3}>
                        <CustomDataGrid
                            rows={sessionRows}
                            columns={sessionColumns}
                            density="compact"
                            hideFooter
                        />
                        <CustomDataGrid
                            rows={transactionRows}
                            columns={transactionColumns}
                            density="compact"
                            hideFooter
                        />
                    </Grid>
                </Grid>
            )}
        </Grid>
    )
}

export default React.memo(ViewCustomer)
