import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
    Person,
    useGetCustomerQuery,
    useUpdateCustomerMutation,
} from '../../../../generated/graphql'
import Loading from '../../../loading'
import CustomerForm from '../customerForm'

const UpdateCustomer = () => {
    const params = useParams()
    const navigate = useNavigate()
    const { id } = params

    const { data, loading: loadingGetCustomer } = useGetCustomerQuery({
        variables: {
            data: {
                id,
            },
        },
    })

    const [updateCustomer, { loading: loadingUpdate }] =
        useUpdateCustomerMutation()

    const submit = React.useCallback(
        (person: Person) => {
            updateCustomer({
                variables: {
                    data: {
                        id,
                        name: person.name,
                        surname: person.surname,
                        email: person.email,
                        phone: person.phone,
                        dni: person.dni,
                    },
                },
                onCompleted(data) {
                    navigate('/people/customers')
                },
                refetchQueries: ['getCustomers', 'getPeople'],
            })
        },
        [navigate, id, updateCustomer]
    )

    const customerForm = React.useMemo(() => {
        return data?.customer ? (
            <CustomerForm customer={data?.customer} submit={submit} />
        ) : null
    }, [data?.customer, submit])

    return loadingGetCustomer || loadingUpdate ? <Loading /> : customerForm
}

export default React.memo(UpdateCustomer)
